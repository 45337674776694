// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/products/Price.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/products/Price.tsx");
  import.meta.hot.lastModified = "1727786050000";
}
// REMIX HMR END

import { CurrencyCode } from '~/generated/graphql';
export function Price({
  priceWithTax,
  baseUnitOfMeasure
}) {
  const currencyCode = CurrencyCode.Chf;

  //className="font-bold text-xl lg:text-2xl"
  if (priceWithTax === null) {
    return <div></div>;
  }
  if (typeof priceWithTax === 'number') {
    return (
      //className="font-bold text-xl lg:text-2xl"
      <div>
        {formatPrice(priceWithTax, currencyCode)}{' '}
        <span className="product-catalog-product-price-type">
          {' '}
          {baseUnitOfMeasure}
        </span>
      </div>
    );
  }
  if ('value' in priceWithTax) {
    return (
      //className="font-bold text-xl lg:text-2xl"
      <div>
        {formatPrice(priceWithTax.value, currencyCode)}{' '}
        <span className="product-catalog-product-price-type">
          {' '}
          /{baseUnitOfMeasure}
        </span>
      </div>
    );
  }
  if (priceWithTax.min === priceWithTax.max) {
    return (
      //className="font-bold text-xl lg:text-2xl"
      <div>
        {formatPrice(priceWithTax.min, currencyCode)}{' '}
        <span className="product-catalog-product-price-type">
          {' '}
          /{baseUnitOfMeasure}
        </span>
      </div>
    );
  }
  return (
    //className="font-bold text-xl lg:text-2xl"
    <div>
      {formatPrice(priceWithTax.min, currencyCode)} -{' '}
      {formatPrice(priceWithTax.max, currencyCode)}{' '}
      <span className="product-catalog-product-price-type">
        {' '}
        /{baseUnitOfMeasure}
      </span>
    </div>
  );
}
_c = Price;
export function formatPrice(value, currency) {
  return new Intl.NumberFormat('de-CH', {
    style: 'currency',
    currency,
    minimumFractionDigits: 2
  }).format(value / 100);
}
var _c;
$RefreshReg$(_c, "Price");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;